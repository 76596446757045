.admin_panel{
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    padding:1rem;
}
.admin_panel h1 {
    margin: 0 1rem;
}
.workShopList_header{
    display: flex;
    justify-content: space-between;
}


.admin_panel_companylist{
    margin: 2rem;
    overflow: scroll;
}


.tableBtn{
    background-color: #171151;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    padding: 1rem;
    width: 6rem;
    height: 2rem;
    border: none;
    border-radius: 1rem;
}

.modelButton > Button{
    background-color: #171151 !important;
    color: #fff !important;
    margin-top: 1rem !important;
}
.coursemodalDate{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}