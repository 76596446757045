.header {
  border-bottom: 2px solid #f5f2f0;
  background-color: #171151;
  border: none;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: .7rem 6rem; */
  gap: 3rem;
  overflow: hidden;
}

.login {
  border-radius: 0.3rem;
  background-color: #f2b036;
  font-weight: 500;
  align-items: center;
  width: 15%;
  cursor: pointer;
}

.section1 {
  width: 100%;
  height: 87vh;
  overflow: hidden;
}

.section1 img {
  width: 100%;
  /* padding: .5rem; */
  height: 100%;
  object-fit: contain;
  border-radius: .3rem;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.inn_sec1 button {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 34px;
  background-color: #f2b036;
  border: none;
  border-radius: .3rem;
}



.section2_info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin-top: 2rem;
}

.info_1_img>img {
  width: 100%;
  object-fit: cover;
}

.section3 {
  padding: .7rem 6rem;
}

h4,
p {
  margin: 0;
}

.info_1>h4 {
  font-size: large;
}

.info_1>p {
  color: #8a7d61;
  font-weight: 450;
}

.section3_info {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2.5rem;
}

.section3_card_img {
  height: 90%;
}

.section3_card_img>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.3rem;
}


.footer {
  width: 100%;
  margin-top: 5rem;
  /* border-top: 0.2rem solid #000; */
  background: url(../../assets/Footer-bg.png);
  color: white !important;
}



.servicesCardlist {
  font-family: "Roboto Medium";

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-top: 1rem;
}

.servicesCard {
  min-height: 16rem;
  padding: 1rem;
  margin: .5rem;
  border-radius: .3rem;
  border: 1px solid darkgray;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  transition: transform 500ms ease-in-out;
  transition-delay: 1s;
}

.animation:hover {
  transform: scale(1.03);
  /* Increase the scale factor slightly */
  transition-duration: 600ms;
}


.servicesCard_img {

  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: .5rem;
  display: grid;
  place-items: center;
  position: relative;
}

.servicesCard_img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}




.section .CusPara {
  margin-top: 1rem;
  text-align: justify;
  font-size: 1.1rem;
}

.serviceReadMore {
  position: absolute;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeadingClass {
  margin: 0px;
  padding: 0px;
}

.section4 {
  margin: 0.7rem 6rem;
}

.section5 {
  width: 100%;
}

.section6 {
  width: 100% !important;

}

.section4_Desc {
  margin-left: -1rem;
  font-size: 1.3rem;
  text-align: justify;

}


.menuBar {
  width: 100%;
  display: grid;
  place-content: center;
  line-height: 2rem;
}
.menuBar_link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;  
  padding:.8rem 0;
  border-bottom: 1px dashed #ffffff1c !important;
  padding:.2rem auto;
  color: #fff;
  font-size: .9rem;
  text-decoration: none;
  text-align: center;
}



.open{display: none;}


.courseContent{
  text-align: justify;
}

@media only screen and (max-width: 768px) {

  .section1,
  .section2,
  .section3,
  .section4,
  .section5,
  .section6 {
    margin: 0 3rem !important;
    text-align: center;
  }

  .section1{
    height: 70vh;
  }


  .section4_Desc {
    font-size: 1.1rem;
  }

  .section3>.servicesCardlist {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .section5{
    margin: 0;
  }

}

@media only screen and (max-width: 991px) {



  .section3>.servicesCardlist {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .servicesCard {
    min-width: 13rem;
  }
}



@media only screen and (max-width: 600px) {


  .courseContent{
    font-size: .9rem;
    margin-bottom: 1rem;
  }
  .footer{
    margin-top: 0;
  }
  .section5{
    margin: 0 3rem !important;
  }
  .container {
    width: 100%;
    margin: 0;
    gap: 2rem;
    z-index: -1;
  }


  .open {
    display: block;
    position: fixed;
    top: 4rem;
    left: 0;
    width: 0; /* Initially hide the sidebar */
    height:0;
    background-color: #171151;
    overflow: hidden;
    transition: width 0.5s ease, transform 0.5s ease; /* Add transition properties */
    transform: translateX(-100%); /* Move sidebar off-screen initially */
  }
  
  .show {
    top: 4rem;
    height:100%;
    width: 200px; /* Set width of the sidebar when it's open */
    transform: translateX(0); /* Move sidebar into view */
  }


  .section1{
    height: 100%;
  }




  .section1,
  .section2,
  .section3,
  .section4,
  .section5,
  .section6 {
    margin: 0 3rem !important;
    text-align: center;
  }

  .section3>.servicesCardlist {
    display: grid;
    gap:0;
    grid-template-columns: repeat(2, 1fr);
  }

  .servicesCard {

    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1rem;
    /* margin: 1rem; */
  }

  .section1>button {
    background-color: #f2b036;
    border-radius: .3rem;
    border: none;
  }

  .section2_info {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 2rem;
  }

  .section4_Desc {
    font-size: 1rem;
  }

  .section3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .section3_info {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: 0 3rem;
    font-size: large;


  }



  .li {
    margin-bottom: 1.2rem;
  }


  .section6_info {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}



@media only screen and (max-width: 480px) {
 
  .section5{
    margin: 0 3rem !important;
  }

  .footer{
    margin-top: 0;
  }
  .section3>h1 {
    font-size: larger;
  }

  .section3>.servicesCardlist {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .servicesCard {
    min-width: 20rem;
  }

  .servicesCard_img {
    margin-top: -2rem;
  }

}