.empDetailPage{
  background: var(--background-gradient);

}

.EmpList  h1{
  margin: 0;
}

.empDetailwrp{
  overflow: auto;
  padding: 2rem;
  min-height: 100vh;
}

.EmpList_card{
  background-color: white;
  padding: 1rem;
  min-width: 16rem;
}
.EmpList_card > h2{
  margin: 0px !important;
  font-size: 1.1rem;
}
.EmpList_card > p{
  margin-bottom: 0.3rem;
}
.empDes{
  margin-top: 0.3rem;
}
.EmpList_cards {
  border-radius: 1rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
  margin-bottom: 2rem;
}
@media screen and  (max-width: 1370px) {
  .EmpList_cards{

    grid-template-columns: repeat(4, 1fr);
  }
  
}



.EmpList_card {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  /* min-height: 14rem; */
  /* width: 14rem; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 500ms ease;
  min-height: 17rem;
  

  /* transition-delay: .2s; */
}


.EmpList_card:hover {
  transform: scale(1.1);
  transition-duration: 600ms;
}



.EmpList_card_img_wrp {
  width: 7rem;
  height: 7rem;
  margin-bottom: 1rem;
  font-size: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EmpList_card_img_wrp img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 50%;
}


.EmpList_card button{
  background-color: none;
  padding: .5rem 1rem;
  margin-top: 1rem;
  background-color: var(--primary-backgroundColor);
  color: white;
  border-radius: 1rem;
  border: none;
}

.Link{
  color: #fff;
}

.ellips{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 205px !important;
  white-space: nowrap;
  
}



@media screen and  (max-width: 1500px) {
  .EmpList_cards{
    grid-template-columns: repeat(4, 1fr);
  }
  
}

@media screen and  (max-width: 1100px) {
  .EmpList_cards{
    grid-template-columns: repeat(3, 1fr);
  }
  
}
@media screen and  (max-width: 781px) {
  .EmpList_cards{

    grid-template-columns: repeat(2, 1fr);
  }
  
}
@media screen and  (max-width: 600px) {
  .EmpList_cards{
    padding: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
  
 

  .EmpList h1{
    padding: 0 1rem;
    font-size: 1.3rem;
  }
}