

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  margin: 0px 2rem;
  position: sticky;
  top: 0;
}
nav .header_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: .3rem;
}
nav .header_logo img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.navlinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  color: #fff;
}

.navlink {
  background-color: orange;
  border-radius: 0.3rem;
  font-weight: 800;
  text-decoration: none;
  color: #003049;
  padding: .3rem;
}
.navlink > .navlink_link {
  background: none;
  border: none;
  font-weight: 600;
}

.navlink_link:hover {
  color: #fff;
}

nav .menu {
  display: none;
  position: absolute;
  right: 0;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-size:2rem;
}



@media only screen and (max-width: 600px) {
  nav .menu {
    display: flex;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navlinks{
    display:none;
  }
}
