.profile_photo {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right: 1rem;
}

.profile_photo img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 50%;
}

.headerLeft {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerLogo {
    width: 6rem;
    height: 3rem;
}

.headerLogo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* mix-blend-mode: multiply; */
}

.chatDropdown {
    background-color: #fff;
    padding: .3rem 1.5rem;
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 1rem;
}

.flex {
    display: flex;
    align-items: flex-start;
}

.dot {
    /* w/ */
    border-radius: 50%;
    background-color: #000;
    opacity: 0.8;
    margin-top: -1rem;
}

.dpWrp {
    background-color: #fff;
    max-width: 260px;
    padding: 1rem;
    border-radius: 0.2rem;
    max-height: 200px;
    overflow: auto;
}

.notification {
    margin-right: 1rem;
    cursor: pointer;
    margin-top: .5rem;
}

.menubarControlBtn {
    margin: 0 1rem !important;
    font-size: 1.2rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description {
    margin-bottom: 1rem;
    margin-left: 0.5rem;
}

.menuCloBtn,
.menuOpBtn {
    width: 2rem;
    height: 2rem;
    z-index: 3 !important;
}

.menuCloBtn {
    z-index: 3 !important;
    color: #fff;
}


@media screen and (max-width: 600px) {
    .headerLogo {
        width: 4rem;
        height: 2rem;
    }

    .profile_photo {
        width: 2rem;
        height: 2rem;
    }

    .profileName {
        font-size: .9rem;
    }

    .menuCloBtn{
        margin-top: 1.5rem !important;
        margin-left: 1.5rem !important;

    }

    .menubarControlBtn {
        z-index: 4 !important;
    }
}