.calenderPageWrp{
    background: var(--background-gradient);
}

.CalHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
    h1{
        
        margin: 0;
    }
}




.calenderpage{
    padding: 2rem;
}
.calenderpageWrp{
    padding: 2rem;
    border-radius: 0.5rem;
}



.calender{
    padding: 2rem;
    margin-top: 1rem;
    border-radius: 1rem;
    background-color: #fff;
    overflow:auto;
}
.content{
    list-style: none !important;
}

.upComCourseList{
    padding: 0;
}

.modalViewMoreBtn{
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    
}

.modalViewMoreBtn Button{
    background-color: orange;
    color: black;
    border: 1px solid black;
}
.modalViewMoreBtn Button:hover{
    background-color: green;
    color: black;
}

.modalDataWrp{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: .5rem;
}

.modalDataWrp p{
    font-size: medium;
    font-weight: 700;
}
.Dot{
    display: flex;
    align-items: center;
    width: .5rem;
    height: .5rem;
}
.Dot img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.modalCourseList :hover{
    color: var(--primary-backgroundColor);
    text-decoration: underline;
}


.success1{
    position: absolute;
    background: #7fdda5 !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
    overflow: auto;


}
.error1{
    position: absolute;
    background: #ff0000a8 !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
    overflow: auto;


}
.warning1{
    position: absolute;
    background: #ffa5007d !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
    overflow: auto;

}
.subOrdinateBg{
    position: absolute;
    background: #ffff007d !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
    overflow: auto;

}
.adminBg{
    position: absolute;
    background: #c6fff4b3 !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
    overflow: auto;

}
.selDate{
    margin-top: 1rem;
    overflow: auto;
}
.flexBetween{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.contenWrp{
    font-size: 1rem;
}


@media screen and (max-width: 600px) {
    .CalHeader{
       display: grid;
       margin: 0;
        margin-bottom:1rem;
    }
    .calenderpage{
        /* padding: 0.5rem !important; */
    }
}
@media screen and (max-width: 500px) {
    .calender{
        padding: 0.5rem;
        width: 100%;
        border-radius: .5rem;
    }
    .contenWrp{
        font-size: .7rem;
    }
    .calenderpage{
        padding: .5rem;
    }
}