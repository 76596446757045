.mainContainer {
    padding: 1rem;
    background-color: #eef2f6;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 2rem;
}

.mainListWrp {
    min-height: 100% !important;
    background-color: #f8fafc;
}

.userListDetail {
    background-color: #f8fafc;

}

.smWrp {
    display: flex;
    padding: 0.5rem;
    border-bottom: 1px solid rgb(237, 231, 246);

}

.menuWrp {
    & :hover {
        background-color: rgb(237, 231, 246) !important;
        cursor: pointer;
    }
}

.smWrp{
    .smImg {
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;
        display: inline;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.nameInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.4rem;
}

.rightInfo {
    width: 100%;
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.userListDetail {
    margin: 1rem 0px;
    max-height: 470px;
    overflow: auto;
}

.lastMessageInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.count {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #673ab7;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 0.9rem;
}

.name {
    font-weight: 600;
    font-size: 0.875rem;
}

.message{
    color: #697586;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Limits to 3 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
    max-width: 11rem;
    font-size: 0.875rem;
}


.demoProfilePhoto{
    font-size: 2rem;
}
.subHeader {
    padding: 1rem 0.5rem;
}

.noData {
    min-height: 400px;
}

.inp {
    border: none !important;
    outline: none !important;
    font-size: 0.9rem;
}


.wrapper {
    background-color: #f8fafc;
    width: 100%;
    min-width: 420px;
    min-height: 400px;
    padding: 1rem 0.5rem;
}

.chatHistory {
    background-color: #f8fafc;
    min-height: 370px;
    padding: 0.5rem;
    max-height: 100%;
    // overflow: auto;
}

.chatInput {
    font-size: 1.1rem;
    background-color: #171151a3;
    border-top: 2px solid #e3e8ef;
    width: 100%;
    padding: 1rem;
    position: relative;
}

.input {
    width: 100%;
    border: none !important;
    font-size: 0.9rem;
}

.chatInput Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.3rem;
    border-radius: 50%;
}

.sendBtn {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    right: 1.5rem;
    bottom: 1.2rem;
    background-color: rgba(23, 17, 81, 0.6392156863);
    padding: 1rem;
    font-size: 1.1rem;
    padding: .5rem;
}

.messageContent {
    border-radius: .3rem;
    background-color: #171151;
    // min-width: 6rem;
    padding: .2rem .5rem;
    max-width: 50%;
    width: fit-content;
    margin-bottom: 0.5rem;

}

.adminMsg {
    background-color: #e2e2e2 !important;
}

.employeeMsg {
    background-color: #e3f2fd !important;

}

.rowRight {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin: .5rem;
}

.chatInput {
    font-size: 1.1rem;
    background-color: #ede7f6;
    border-top: 2px solid #e3e8ef;
    width: 100%;
    padding: 1rem;
    position: relative;
}

.active {
    background-color: rgb(237, 231, 246) !important;

}

.chatDetailHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e3e8ef;
    // background-color:#171151a3;
}

.headerWrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    color: #000;

}

.headerImage {
    width: 2.5rem;
    height: 2.5rem;
}


.headerImage img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: fill;
}

.designation {
    color: #000 !important;
}

.headerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: -.5rem;
}

.headerContent h3 {
    margin: 0;
}

.headerContent p {
    color: #fff;
    font-size: .8rem;
}


.backBtn{
   font-size: 1.5rem;
}
@media screen and (max-width:700px) {
    .mainContainer{
        grid-template-columns:1fr;
    }
    .chatHistory{
        min-height: 78% !important;
    }
    .wrapper{
        min-height: 640px !important;
        min-width: 300px !important;
    }
}
