.profileWrapper {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
}

.profilepageWrp {
    background: var(--background-gradient);
}

.profileWrp {
    font-family: 'Roboto Medium';
    padding: 2rem;
}

.profileData {
    /* display: grid;
    grid-template-columns: repeat(2,1fr); */
    /* grid-gap: 3rem; */
    /* margin: 1.5rem; */
    display: flex;
    align-items: center;
    font-size: larger;
    padding: 0rem 2rem;
}

.profileHeader>h1 {
    margin: 0;
}

.profileContent {
    margin-top: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
}

.editbtn {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin: 2rem;
    /* background-color: aqua; */
}

.edit {
    display: flex;
    gap: 0.4rem;
    justify-content: space-between;
    border: none;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    /* font-size: medium; */
    background-color: #FFA500;
    cursor: pointer;
}

.logindata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0.65rem;
}

/* .logindata :hover{
    border:1px solid #FFA500;
  } */
.input {
    margin-top: 0.5rem;
    padding: 0.8rem 2rem 0.8rem 0.5rem;
    color: #8a7d61;
    font-size: medium;
}

.basic_info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-top: 1rem;
}

.value {
    margin-left: 0.5rem;
}

.footerBtn {
    background-color: #171151 !important;
    /* color: #000 !important; */
    color: white !important;
    height: 40px;
    margin: 1rem 0rem;
    border: none;
}


.logindata label {
    color: black;
    font-weight: 700;
}

.logindata_input label {
    color: black;
    font-weight: 700;
}

.showPdf{
    width:100vh;
    a{
        text-decoration: none;
        color: #fff;
    }
}

.uploadPhoto {
    width: 50%;
    height: 8rem;
    padding: 1rem;
    border-radius: .3rem;
    border: 2px solid white;
}

.profilePhoto {
    width: 5rem;
    height: 5rem;
}


.profilePhoto img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.checkBoxWrp {
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin:1rem 0;
    input{
        width: 1.5rem;
        height: 1.5rem;
    }
    
}


@media screen and (max-width: 991px) {
    .signupWrapper {
        margin: 1rem 2rem;
        /* display: block; */
    }

    .basic_info {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media screen and (max-width: 600px) {

    .basic_info {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0;
    }

    .uploadPhoto{
        width: 100%;
    }

   
}