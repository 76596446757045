.EmpDashboard {
    background: var(--background-gradient);
height: fit-content;
}

.DashboardWrp {
    font-family: 'Kigelia';
}


.dashboard_employee_graph_1 {

    border-radius: 0.5rem;
    width: 100%;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
}

.sec2card1 h2 {
    margin-left: 1rem;
}

.sec1IsOnO,
.sec2IsOnO,
.sec3IsOnO {
    min-width: 100%;
    border-radius: 1rem;
    background-color: #fff;
    min-height: 30rem;
    max-height: 30rem;
    overflow: auto;
}

.sec1CardChart1OnO {
    text-align: center;
}

.sec1,
.sec3 {
    border-radius: 1rem;
    background-color: #fff;
    min-height: 35rem;
    max-height: 35rem;
    
    
}

.sec3{

    overflow: scroll;
}

.sec1,
.sec2,
.sec3 {
    min-width: 100%;
}



.sec1CardChart1OnO,
.sec1CardChart2OnO {
    min-width: 100%;
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 2rem;
    max-height: 210px !important;
}

.sec2card1OnO {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 1rem;
    background-color: #fff;
    min-height: 30rem;
    max-height: 30rem;
    padding: 1rem;
}


.sec2Chart1Wrp {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.sec3IsOnO {
    padding: 1rem;

    h1 {
        margin: 0;
    }
}


.sec1CardChart1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
}

.sec1CardChart2 {
    padding: 2rem;
    max-height: 120px;
}

.sec2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}



.sec2card1,
.sec2card2,
.sec2card3 {
    width: 100%;
    background-color: #fff;
    border-radius: 1rem;
    min-height: 11rem;
    overflow: hidden;
}


.sec2Card1ChartWrp {
    padding: 0 1rem;
    margin-top: 1rem;
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-right: .5rem auto;
}

.RankHeading {
    margin-top: -1rem !important;
    margin-left: 1rem !important;
}



.sec1Card1,.sec1Card2 {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: 1rem !important;
}

.sec1Card2{
    margin-bottom: 1.5rem;
}

.sec1Card1 p {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
    margin: .2rem;
    text-align: center;
}

.sec3 h1 {
    margin: 1rem;
}

.sec3Card1 {
    display: flex;
    gap: 2rem;
    padding: 1rem;
    border-radius: .3rem;
    font-family: 'Kigelia' !important;
    margin-bottom: 1.5rem;
}

.sec3Card1Img {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;

}

.sec3Card1Img img {
    width: 100%;
    height: 100%;
    border-radius: .3rem;
    border: 1px solid black;
    object-fit: fill;
}

.sec3Card1Content {
    padding: 0;
}

.sec3Card1Content h2 {
    margin: 0;
}

.sec3Card1Content p {
    font-size: 1.1rem;
    text-align: left;
}


.dashboard_events {
    background-color: #fff;
    margin-top: 2rem;
    border-radius: .5rem;
    padding: 2rem;
}


.event_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
}

.eventCard {
    border: 1px solid orange;
    margin-top: 3rem;
}

.eventImg {
    width: 100%;
    height: 10rem;
}

.eventImg img {
    width: 100%;
    margin: .2rem;
    height: 100%;
    object-fit: fill;
}

.dashboard_employee_lavel_graph {

    margin-top: 2rem;
}

.chart {
    overflow-y: auto;
    margin-top: 1rem;
    border-radius: 1rem;
    padding: 2rem;
    background-color: #fff;
}

.btn {
    padding: 0.5rem 1rem;
    background-color: var(--primary-backgroundColor);
    color: #fff;
    border-radius: 0.2rem;
    border: none;
    font-weight: 600;
}

.btn:hover {
    background-color: var(--secondary-backgroundColor);
    cursor: pointer;
}

.graphHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
}

.graphHeader h1 {
    margin: 0;
}


.profilePhto {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profilePhto img {
    width: 10rem;
    height: 100%;
    border-radius: 5rem;
    object-fit: fill;
}

.TableHeaderSRNo {
    background-color: rgb(12 79 77);
    padding: 0.5rem;
    color: #fff;
    margin: 0rem 0.25rem;
    text-align: center;
    border-radius: 0.5rem 0rem 0rem 0rem
}

.tableSrNoData {
    background-color: #f6f6f6;
    padding: 0.5rem;
    margin: 0rem 0.25rem;
    text-align: center;
    height: 48;
}

.CourseHeader {
    background-color: rgb(225 142 0);
    padding: 0.5rem;
    color: #fff;
    margin: 0rem 0.25rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48;
    font-size: large;

}

.CourseData {
    background-color: #f6f6f6;
    padding: 0.5rem;
    margin: 0 0.25rem 0.2rem 0.25rem;
    text-align: center;
    font-size: 21;
    height: 48;

}

.tableDateHeader {
    background-color: rgb(133 178 31);
    padding: 0.5rem;
    color: #fff;
    margin: 0rem 0.25rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48;
}


.tableDateData {
    background-color: #f6f6f6;
    padding: 0.5rem;
    margin: 0rem 0.25rem;
    text-align: center;
    height: 48;
}

.tableAction {
    background-color: #85096a;
    padding: 0.5rem;
    color: #fff;
    margin: 0rem 0.25rem;
    text-align: center;
    border-radius: 0rem 0.5rem 0rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48;

}


.tableActoinButton {
    background-color: #f6f6f6;
    margin: 0rem 0.25rem;
    padding: 0.32rem;
    text-align: center;
    height: 48;
}

@media screen and (max-width: 1400px) {
    .sec3 {
        overflow: auto;
    }
}

@media screen and (max-width: 991px) {
    .coursesWrp {
        grid-template-columns: repeat(2, 1fr);
    }

    .sec2card1>h2 {
        font-size: large;
        gap: 0;
    }

    .sec2Card1ChartWrp {
        gap: 0;
    }

    .dashboard_cards {
        grid-template-columns: repeat(1, 1fr);
    }

    .inline_header h2 {
        position: relative;
        top: 0;
        left: 0;
        padding: .5rem 1rem;
        text-align: center;
    }

    .inline_header {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
    }
}

@media screen and (max-width: 791px) {
    .dashboard_employee_graph_1 {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media screen and (max-width: 600px) {
    .coursesWrp {
        grid-template-columns: 1fr;
    }

.TableHeaderSRNo,
.tableSrNoData,
.CourseHeader,
.CourseData,
.tableDateHeader,
.tableDateData,
.tableAction,
.tableActoinButton{
    font-size: .8rem;
}


.sec3IsOnO{
    min-height: 20rem !important;
    overflow: hidden !important;
}
/* 
    .EmpDashboard{
        min-height: fit-content;
    } */

    .graphHeader{
        display: grid;
        gap: 1rem;
    }
    .dashboard_cards {
        grid-template-columns: 1fr;
    }

    .dashboard_employee_graph_1 {
        grid-template-columns: 1fr;
    }

    .sec1 {
        min-height: 28rem;
    }

    .sec1CardChart1 {
        margin-top: 1rem;
    }

    .chart{
        padding: 1rem;
    }

    .sec2Card1ChartWrp {
        gap: 1rem;
        margin-top: 0;
        margin: 1rem;
    }

    /* .sec1CardChart2{
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    } */
    .DashboardWrp {
        h1 {
            font-size: x-large;
        }
    }

}

.sec1Content {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.4rem;
    margin: 0.4rem;
    text-align: justify;
}

.HeadingClass {
    margin: 0;
    padding: 0;
}


.ellips {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px !important;
    white-space: nowrap;
    margin: auto 0;

}

.head {
    margin: 0px !important;
}