.footerWrapper {
  margin-left: 4rem;
  padding: 4rem;
  bottom: 0;
  height: 100%;
  font-family: 'Segoe UI Semibold';
}

.footer_card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.footer_logo {
  height: 5rem;
  width: 5rem;
}

.footer_1 p {
  color: orange;
}


.footerLogo {
  width: 10rem;

}

.footerLogo>img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.footer_2 {
  display: grid;
  place-content: center;
  line-height: 2rem;
}

.footer_2_link {
  color: orange;
  text-decoration: none;
  text-align: left;
  font-weight: 600;
}

.footer_2_link:hover {
  color: white;
}


.footer_3>h2 {
  margin: 0;
  color: orange;
}

.footer_3 p {

  color: orange;
}

.footer_3>p :hover {
  color: white;

}

.footer_3>.footer_contact_info {
  line-height: 2rem;
  margin-top: 1rem;
  font-weight: 600;
}

.footer_3>.footer_social_media {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  font-size: 1.5rem;
}

.footer_social_media>a {
  color: orange;
}

.footer_social_media>a :hover {
  color: white;
}

.copy_rights {
  margin-top: 4rem;
  text-align: left;
  font-weight: 600;
  word-spacing: 0.2rem;
  color: orange;
}


.footer_3 span:hover {
  color: white;
}

.userManual {
  text-decoration: none;
  color: orange;
}

.userManual:hover {
  color: white;
}



@media only screen and (max-width: 786px) {
  .footer_card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

}

@media only screen and (max-width: 600px) {
  .footerLogo {
    width: 7rem;
  }

  .footerWrapper {
    margin-left: 0;
    min-height: 60rem;
  }


  .footer1>P {
    font-size: 1rem;
  }

  .footer_card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }

  .footer_3 {
    h2 {
      font-size: 1.2rem;
    }

    p,
    span {
      font-size: 1rem;
    }
  }

  .footer_2 {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1rem 0;

    line-height: 2rem;
  }


  .copy_rights {
    margin-top: 2rem;
  }
}


@media only screen and (max-width: 480px) {
  .footerLogo {
    width: 7rem;
  }

  .footer_card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }

  

  .footer_2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1rem 0;
    line-height: 2rem;
  }

}




.flagWrp {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.flagWrp span {
  color: orange;
  letter-spacing: .1rem;
}

.flagWrp span:hover {
  color: white;
}

.flag {
  width: 1.5rem;
  height: 1.5rem;
}

.flag img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: fill;
}


.email {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: orange;
}