.userManualPage {
    background: var(--background-gradient);
}

.maualWrp {
    padding: 2rem;
  min-height: 100vh;

}

.maualWrp h1 {
    margin: 0;
    margin-bottom: 1rem;
}

.displayControlls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
}

.controllsAndInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

}



.pdfInput {
    display: flex;
    flex-direction: column;
    justify-content: center;

    label {
        font-weight: 700;
    }

    input {
        border: 1px solid black;
        border-radius: .3rem;
        margin-top: .3rem;
        padding: .5rem .5rem;
        border: none;
    }
}

.contrBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background-color: #171151;
    color: #fff;
    font-weight: 700;
}

.pdfCanvas {
    width: 100% !important;
    max-width: 100% !important;
    max-height: 80% !important;
}


@media screen and (max-width: 600px) {

    .displayPageNo{

        width: 2.5rem;
        display: flex;
        justify-content: center;
    }

    .displayControlls{
        gap: .5rem;
        padding: 0 .5rem;
    }

    .controllsAndInput{
        align-items: flex-end;
    }

    .pdfInput{
        input{
            max-width: 10rem;
        }
    }
}