.AdminHome_main {
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    padding:  .2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminHome_main img {
    width: 100%;
    height: 80vh;
    object-fit: fill;
    border-radius: 0.5rem;
}

.AdminHome_services,.AdminHome_services_list {
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    overflow: hidden;
    padding: .5rem 1rem;
}

.AdminHome_servicescards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  
    padding: 2rem;
    grid-gap: 2rem;
}
.AdminHome_workshopCards{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 2rem;
    padding: 2rem;
}

h1{
    margin: 1rem 0 0 2rem;
}


.CusCard{
    border: '1px solid rgba(0, 0, 0, 0.05)';
    background-color: #f29a36;
    min-height: 12rem;
   }



.adminhome_card_icons{
    display: grid;
    place-content: center;
}
.adminhome_card_icons img{
    width: 8rem;
    height: 8rem;
}

.adminHome_serCard_content{
    display: flex;
    flex-direction: column;
}

.AdminHome_services_cardlist{
   display: grid;
   grid-template-columns: repeat(2,1fr);
   grid-gap: 1rem;
    border-radius: .3rem;
    padding: 1rem;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
}

.adminHome_serCard_content h2{
    margin: 1rem 0;
}



.servicesCard{
    
    border-radius: .3rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1rem;
    /* margin: 1rem; */
  }

  
.servicesCard_img{
  
    width: 3rem;
    height:3rem;
    border-radius: 50%;
    padding: .5rem;
    display: grid;
    place-items: center;
    position: relative;
  }
  
  .servicesCard_img img{
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  


.AdminHomePContent{
    font-size: 1.1rem;
    text-align: justify;
    margin: 0 1rem;
}
  .serviceReadMore {
    margin-top: 2rem;
  }


@media screen and (max-width: 991px) {

    .AdminHome_main img {
        height: 50vh;
    }
    .adminhome_card_icons img{
        width: 5rem;
        height: 5rem;
    }
.AdminHome_servicescards{
    grid-template-columns: repeat(2,1fr);
}
    .AdminHome_services {
        text-align: center;
        overflow: auto;
    }

    h1 {
        margin: 0 1rem;
    }

    .AdminHome_services_card,.AdminHome_services_list {
        text-align: center;
    }

    h2 {
        margin: 0;
    }
}

@media screen and (max-width: 791px) {
    .AdminHome_main img {
        height: 30vh;
    }
    .AdminHome_servicescards {
        padding: 1rem;
        grid-template-columns: repeat(2,1fr);
    }

    /* .AdminHome_servicescards {
        grid-template-columns: 1fr;
        overflow: auto;
        margin-top: 1rem;
    } */
    h1 {
        margin: 1rem;
    }

    .AdminHome_services_card {
        text-align: center;
        padding: 1rem;
    }


    h2 {
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .AdminHome_services_cardlist{
        grid-template-columns: 1fr;
    }

    .AdminHome_servicescards ,.AdminHome_workshopCards{
        grid-template-columns: 1fr;

    } 
}