.admin_panel{
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    padding:1rem;
}
.admin_panel h1 {
    margin: 0 1rem;
}

.admin_panel_companylist{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 1rem;
    grid-gap: 2rem
}

.admin_panel_companylist_card{
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-decoration: none;
    color: black;
}

.admin_panel_com_logo{
    width: 100%;
    height: 10rem;
    margin-bottom: 1rem;
}

.admin_panel_com_logo img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}


.workShopList_header{
    display: flex;
    justify-content: space-between;
}

.modelFormWrp{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 1rem;

    .selectWorkshop{
        display: grid;
    }
}


.pdfPreview{
    max-width: 30rem;
}
.workshopimage{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.coursemodalDate{
    display: grid;
}

.coursemodalDate label{
    color: #505050;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    margin-bottom: 0.2rem;
}
.workshopimage label{
    color: #505050;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    margin-bottom: 0.2rem;

}

.admin_panel_Course_Card{
    min-height: 18rem;
    position: relative;
}
.courseData{
    color: black;
}
.modelButton{
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.wsCardBtn{
    position: absolute;
    bottom: .5rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.previewImage{
    width: 10rem;
    height: 10rem;

}
.modelFormInputWrp{
    display: grid;
    grid-template-columns:repeat(2,1fr); 
    grid-gap:1rem;
}

.ellips {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limits to 3 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
}

@media screen and (max-width: 1440px) {
    .admin_panel_companylist{
        grid-template-columns: repeat(3,1fr);
        overflow: auto;
    }  
    
}

@media screen and (max-width: 991px) {
  .admin_panel_companylist{
        grid-template-columns: repeat(2,1fr);
        overflow: auto;
    }   
}

@media screen and (max-width: 600px) {
    .admin_panel_companylist{
        grid-template-columns: 1fr;
    }
    .admin_panel_companylist_card{
        padding: 1rem;
    }
    .admin_panel_Course_Card{
        min-height: 18rem;
    }
}