
.footer {
    width: 100%;
    margin-top: 5rem;
    background: url(../../../assets/Footer-bg.png);
    color: white !important;
  }
  

  .header {
    border-bottom: 2px solid #f5f2f0;
    background-color: #171151 ;
  }

  .tsCon{
    min-height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tsCon h1{
    margin-top:2rem;
}

.tsCon p{
      width: 60%;
  font-family: "Roboto Medium";
margin-top: 2rem;
    font-size: larger;
  }

  .menuBar_link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;  
    padding:.8rem 0;
    border-bottom: 1px dashed #ffffff1c !important;
    padding:.2rem auto;
    color: #fff;
    font-size: .9rem;
    text-decoration: none;
    text-align: center;
  }


@media only screen and (max-width: 600px) {
  .open {
    position: fixed;
    top: 4rem;
    left: 0;
    width: 0; /* Initially hide the sidebar */
    height: 100%;
    background-color: #171151;
    overflow: hidden;
    transition: width 0.5s ease, transform 0.5s ease; /* Add transition properties */
    transform: translateX(-100%); /* Move sidebar off-screen initially */
  }
  
  .show {
    top: 4rem;
    width: 200px; /* Set width of the sidebar when it's open */
    transform: translateX(0); /* Move sidebar into view */
  }



}