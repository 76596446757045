.sider_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 100%;
    height: 5rem;
}

.sider_logo img {
    width: 100%;
    height: 100%;
    color: black;
    object-fit: contain;
}

.menubarControlBtn {
    position:absolute;
    color: #fff;
    font-size: 1.5rem;
    top: .5rem;
    right: .5rem;
}

.menuCloBtn:hover{
    color: red;
}

.manubarWrp {
    height: 100vh !important;
    background-color: #171151 !important;
    overflow: auto !important;
    position: sticky !important;
    top: 0 !important;

}



.notselectedMenu {
    background-color: #171151;
    font-size: 1.1rem;
}

.selectedMenu {
    background-color: #595293;
    font-size: 1.1rem;
}

.close {
    min-width: 0 !important;
    width: 0px !important;
    z-index: 1 !important;
    position: fixed !important;
    top: 1rem !important;
    margin-top: 2rem !important;

}

@media screen and (max-width: 600px) {

    .manubarWrp {
        min-width: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
        z-index: 3 !important;
        left: -0.1rem;
        position: fixed !important;
    }

    .ant-layout-sider {
        width: 100% !important;
    }
    
.closeBtn {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    font-size: 2rem;
    color: #fff;
}

}