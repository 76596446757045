.selectWrapper{
  min-width: 200px;
  width: 100%;
  height: 40px;
}
.label{
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  color: #505050;

}