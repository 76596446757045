.ceoDeshboardPage {
  background: var(--background-gradient);
}

.ceoDeshboard {
  overflow: auto;
  padding: 2rem;
  font-family: 'Segoe UI Semibold';
}


.ceo_gragh_Wrapper {
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.ceo_gragh_Wrapper h1 {
  margin: 0;
}

.tableRowDatawrp {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.chartAndDisplayCardWrp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}



#ceoChart {
  background-color: #fff !important;
  margin-top: 1rem;
  height: 40rem;
  padding: 1rem;
  border-radius: 1rem;
}


.disalyDataCard {
  display: flex;
  justify-content: center;
  align-items: center;

}

.chartCardData p {
  font-size: 1.04rem;
  font-weight: 600;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 1rem;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  font-weight: 600;
  background-color: #171151;
  color: white;
}

.disalyDataCard>button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: .3rem;
  border: none;
  background-color: #171151;
  color: white;
  border-radius: 50%;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  padding: 0.5rem 1rem;
  background-color: var(--primary-backgroundColor);
  color: #fff;
  border-radius: 0.2rem;
  border: none;
  font-weight: 600;
}

.btn:hover {
  cursor: pointer;
}


.head {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.employeeWrapper {
  margin-top: 2rem;
}

.employeeWrapper h1 {
  margin: 0
}


.empTable {
  overflow: auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 1rem;
}

.empTable_profilePhoto {
  width: 3rem;
  height: 3rem;
}

.empTable_profilePhoto img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 50%;
}


.EmpGrphData {
  margin-top: 1rem;
  position: relative;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  min-height: 12rem;
  min-width: 20rem;

}

#ceoChart {
  width: 100%;
}

.graphWrp {
  display: flex;
  gap: 2rem;
}


.tabelSRHead {
  background-color: rgb(12 79 77);
  padding: 0.5rem;
  color: #fff;
  margin: 0rem 0.25rem;
  text-align: center;
  border-radius: 0.5rem 0rem 0rem 0rem;
}

.tableSRBody {
  margin: .7rem .25rem;
  padding: .32rem;
  text-align: center;
}

.tablePhotoHead {
  background-color: rgb(225 142 0);
  padding: 0.5rem;
  color: #fff;
  margin: 0rem 0.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10rem;
}

.tableTaskHead {
  background-color: rgb(133 178 31);
  padding: 0.5rem;
  color: white;
  margin: 0rem 0.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10rem;

}


.tableDegHead {
  min-width: 13rem;
  background-color: #85096a;
  padding: 0.5rem;
  color: #fff;
  margin: 0rem 0.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


.tableDepHead {
  min-width: 12rem;
  background-color: #c50406;
  padding: 0.5rem;
  color: white;
  margin: 0rem 0.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableActHead {
  min-width: 10rem;
  background-color: rgb(0 96 152);
  padding: 0.5rem;
  color: white;
  margin: 0rem 0.25rem;
  text-align: center;
  border-radius: 0rem 0.5rem 0rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;

}

@media screen and (max-width: 1400px) {
  .coursesWrp {
    grid-template-columns: repeat(3, 1fr);
  }
}



@media screen and (max-width: 991px) {
  .graphWrp {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {


  .EmpGrphData {
    min-width: 15rem;
  }

}