.wrapper>h2 {
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
}

.swiperWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: 1px solid #e2e2e2;
}

.embla {
  --slide-spacing: 1rem;
  --slide-size: 20%;
  --slide-height: 19rem;
  padding: 1.6rem;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
  display: flex;
  align-items: center;

}


.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}

p {
  text-align: justify;
}

.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}

.imageWrp {
  width: 120px;
  height: 100%;
  margin: 1.5rem;

  @media screen and (min-width: 1920px) and (max-width: 3000px) {
    width: 150px;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 3rem !important;
  }
}

.embla__slide__number>span {
  color: var(--brand-primary);
  background-image: linear-gradient(45deg,
      var(--brand-primary),
      var(--brand-secondary));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}



.countryFlag {
  height: 60px;
  width: 90px;
  margin-right: 1rem;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 1920px) and (max-width: 3000px) {
    height: 80px;
    width: 150px;
  }

}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper {
  width: 100%;
  // background-color: #fff !important;
  border-bottom: 1px solid #e2e2e2 !important;

}

.star {
  margin-top: 2rem;
  text-align: left !important;
}

.details {
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Medium";
  margin-top: 1rem;
}

.corosul {
  // padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.header_photo {
  width: 5rem;
  height: 5rem;
}

.header_photo img{
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 50%;
}

.testiHeaderWrp{
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}
.headerContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.maxWidth {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 991px) {
    max-width: 95%;
    margin: 0rem;
  }
}

.review {
  padding: 1rem;
  width: auto !important;
}

.leftSec {
  width: 50%;
  padding-right: 10rem;
}

.noDataWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 600;
  font-size: 1.5rem;
}

.mainHeading {
  font-size: 2.8rem;
  font-weight: bolder;
  padding-bottom: 0.3 rem;
  text-align: center;
  margin-bottom: 2rem;
  font-family: "Roboto Slab", serif;
}


.trademark{
  margin-right: 2rem;
  font-size: 3rem;
}


.Testimonials_desc{
  line-height: 1.8rem;
}

@media (max-width: 991px) {}
@media (max-width: 786px) {
  
  .details{
    margin-top: 1rem;
  }


  .testiHeaderWrp{
    gap: .3rem;
  }
  .star{
    margin-top: 1rem;
  }
  .header_photo{
    width: 3rem;
    height:3rem;
  }

  .Testimonials_desc{
    font-size: .7rem;
    line-height: 1rem;
  }

  .headerContent{
    h4{
      font-size: 1.1rem;
    }
    p{
      max-width: 11rem;
      font-size: .7rem;
    }
  }
  .trademark{margin-left: 1rem;
    font-size: 1.1rem;
  }
}
@media (max-width: 600px) {

  .details{
    margin-top: 1rem;
  }


  .testiHeaderWrp{
    gap: .3rem;
  }
  .star{
    margin-top: 1rem;
  }
  .header_photo{
    width: 3rem;
    height:3rem;
  }

  .Testimonials_desc{
    font-size: .7rem;
    line-height: 1rem;
  }

  .headerContent{
    h4{
      font-size: 1.1rem;
    }
    p{
      max-width: 11rem;
      font-size: .7rem;
    }
  }
  .trademark{margin-left: 1rem;
    font-size: 1.1rem;
  }
}