.footer {
  width: 100%;
  margin-top: 5rem;
  background: url(../../../assets/Footer-bg.png);
  color: white !important;
}


.header {
  border-bottom: 2px solid #f5f2f0;
  background-color: #171151;
}


.mainWrp {
  padding: 2rem 6rem;
}

h1,
h2 {
  margin: 0;


}


h1 {
  margin-top: 2rem;
}

h2 {
  margin-top: 1rem;
}


.menuBar_link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: .8rem 0;
  border-bottom: 1px dashed #ffffff1c !important;
  padding: .2rem auto;
  color: #fff;
  font-size: .9rem;
  text-decoration: none;
  text-align: center;
}


@media only screen and (max-width: 600px) {
  .open {
    position: fixed;
    top: 4rem;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #171151;
    overflow: hidden;
    transition: width 0.5s ease, transform 0.5s ease;
    transform: translateX(-100%);
  }

  .show {
    top: 4rem;
    width: 200px;
    transform: translateX(0);
  }



}