.AdminEmpList {
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    overflow: hidden;
    padding: 1rem;
}

.EmplistWrp {
    min-height: 100vh !important;
}

.enroll_course {
    width: 100%;
    margin: 1rem 0;
}

.optionsWrp {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: .3rem;
    background-color: #fff;
    border: 1px solid #d3c9c9;
}

.coursemodalDate {
    display: flex;
    flex-direction: column;
}

.divider {
    margin: 0 !important;
}

.option_1,
.option_2 {
    font-weight: 600;
    padding: 0.5rem;
    font-size: medium;
    display: grid;
    place-items: center;
}

.option_1 p {
    cursor: pointer;

}

.option_1 p:hover {
    color: #25a3d1
}

.option_2_course {
    color: #000;
}

.AdminEmpList_cards {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;
    padding: 2rem;
    cursor: pointer;
}


.AdminEmpList_cards h2 {
    margin: 0 1rem;
}

.AdminEmpList_card {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.AdminEmpList_card_img_wrp {
    width: 7rem;
    height: 7rem;
    font-size: 6rem;
}

.AdminEmpList_card_img_wrp img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 50%;
}

.AdminEmpList h1 {
    margin: 1rem 2rem;
}

.headderEmplist {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

@media screen and (max-width: 1440px) {
    .AdminEmpList_cards {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 991px) {
    .AdminEmpList_cards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 791px) {
    .AdminEmpList_cards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .AdminEmpList_cards {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 600px) {
    .headderEmplist {
        flex-direction: column;
    }
}