.wrapper {
    background-color: #f8fafc;
    width: 100%;
    min-width: 420px;
    min-height: 500px;
    margin-left: -25rem;
    overflow: hidden;
    border-radius: 1rem;
}

.headerWrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.headerImage {
    width: 2.5rem;
    height: 2.5rem;
}


.headerImage img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: fill;
}

.headerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: -.5rem;
}

.headerContent h3 {
    margin: 0;
}

.headerContent p {
    color: #fff;
    font-size: .8rem;
}



.ChatBoxWrapper {
    background: var(--background-gradient);
}

.ChatWrapper {
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.chatDetailHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e3e8ef;
    background-color: #171151a3;
    color: white;
}


.chatbox {
    background-color: #f8fafc;
    border-radius: 1rem;
    width: 60%;
    overflow: hidden;
    // border: 2px solid #f8fafc;
}

.chatHistory {
    background-color: #f8fafc;
    min-height: 25rem;
    padding: 0.5rem;
    max-height: 220px;
}


.chatInput {
    font-size: 1.1rem;
    background-color: #171151a3;
    border-top: 2px solid #e3e8ef;
    width: 100%;
    padding: 1rem;
    position: relative;
}

.input {
    width: 100%;
    border: none !important;
}

.chatInput Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.3rem;
    border-radius: 50%;
}

.sendBtn {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    right: 1.5rem;
    bottom: 1.2rem;
    background-color: rgba(23, 17, 81, 0.6392156863);
    padding: 1rem;
    font-size: 1.1rem;
    padding: .5rem;
}

// .chatInput 

.rowRight {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin: .5rem;
}

.rowLeft {
    display: flex;
    margin: 1rem;
    justify-content: flex-start;
}


.messageContent {
    border-radius: .3rem;
    background-color: #171151;
    min-width: 6rem;
    padding: .2rem .5rem;
    max-width: 50%;
}

.adminMsg {
    background-color: #e2e2e2 !important;
}

.employeeMsg {
    background-color: #e3f2fd !important;

}


.ant-float-btn-group {
    bottom: 20px !important;
}




@media screen and (max-width: 791px) {
    .wrapper {
        min-width: 23rem;
        min-height: 27rem;
        margin-left: -21rem;
    }
}

@media screen and (max-width: 400px) {
    .wrapper {
        min-width: 21rem;
        min-height: 33rem;
        margin-left: -18rem;
    }
}