
.App {
  text-align: center;
  width: 100%;
  height: 100%;
}


/* @font-face {
  font-family: "GlacialIndifference-Regular";
  src: url('/src/Font/GlacialIndifference-Regular.otf') format('truetype');
} */

@font-face {
  font-family: "GlacialIndifference-Regular";
  src: url(../src/Font/GlacialIndifference-Regular.otf) format('truetype');
}

@font-face {
  font-family: "Kigelia";
  src: url(../src/Font/Kigelia.ttf) format('truetype');
}

@font-face {
  font-family: "Segoe UI Semibold";
  src: url(../src/Font/Segoe\ UI\ Semibold.ttf) format('truetype');
}

@font-face {
  font-family: "Roboto Medium";
  src: url(../src/Font/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: "Aptos";
  src: url(../src/Font/aptos-font/aptos-semibold.ttf) format('truetype');
}


.heading{
  margin: 0;
  font-family: "Roboto Medium";

}

.beforeLoginContenet{
  font-family: "Roboto Medium";
}


.footer {
  width: 100%;
  margin-top: 5rem;
  /* border-top: 0.2rem solid #000; */
  background-color: #f2b036;

}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

section {
  --xs: 480px;
}


@media print {
  html,body{
    display: none;
  } 
}


.ant-table-wrapper{
  min-width:40rem !important;
}


 
.react-pdf__Page__textContent{
  width: 70% !important;
  height: 60% !important;
}

#pdf-download,#pdf-zoom-out,#pdf-zoom-in,#pdf-zoom-reset,#pdf-toggle-pagination,#header-bar{
  display: none;
}

.cui-toolbar-buttondock alignright
{
  display: none;
}

#WACStatusBarContainer {
  display: none;
}

/* variables.css */
:root {
  /* --primary-color: #3EECAC;
  --secondary-color: #EE74E1; */
  --primary-color: #3eecac;
--secondary-color: #e8c59a;
  --background-gradient: linear-gradient(19deg, var(--primary-color) 0%, var(--secondary-color  )70%);
  --primary-backgroundColor :#171151;
  --secondary-backgroundColor :#8A879F;
  /* font-family: GlacialIndifference-Regular; */
  
}

.radial-bar-chart-label {
  white-space: pre-wrap; /* Allow wrapping */
  word-wrap: break-word; /* Allow breaking long words */
}


.ant-table-thead .ant-table-cell{
  font-size: 1.2rem !important;

}

.ant-menu-item{
  color: aliceblue !important;
}

#video-renderer{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #171151;
}
.llDHEW{
  width: 70% !important;
  height: 100% !important;
  border: 3px solid white !important;
  border-radius: .5rem;
  /* mix-blend-mode: multiply; */
}
.ant-picker-content > thead > tr > th{
  color: white !important;
  text-align: center !important;
}


.ant-input ,.ant-select-selection-item{
  color: black !important;
  font-size: 1.1rem;
}
.ant-table-cell{
  font-size: larger;
  font-weight: 600;
}
.slick-prev {
  margin-left: 40px;
}

.slick-next {
  margin-right: 40px;
}
.carousel-slider{
  display: flex !important;
  align-items: center !important;
}


.ant-float-btn-group{
  bottom: 20px !important;
}

