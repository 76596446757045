body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.accordion {
  /* border: 1px solid #ccc; */
  transition: 0.3s ease-in-out;
}

.accordion-item {
  transition: 0.3s ease-in-out;
  border-bottom: 1px solid #ccc;
}

.accordion-title {
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 5px;
}

.ant-menu-submenu-title {
  color: #fff !important;
}


.accordion-title.active {
  background-color: #ddd;
}

.accordion-content {
  padding: 10px;
  display: none;
}

.accordion-content.open {
  display: block;
}

.custom-collapse .ant-collapse-header {
  color: #171151 !important;
  font-size: 1.15rem !important;
  font-weight: 700;
  /* Set the desired header text color */
  font-family: 'Aptos';
}

.custom-collapse-inner .ant-collapse-header {
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: black !important;
}

.ant-table-cell {
  /* background-color: #8A879F !important; */
  border: none !important;

}


.ant-card-cover img {
  width: auto;
}

#apexchartsbi2vragmg,
#SvgjsG1978 {
  width: 100%;
}

#msdoc-renderer {
  min-height: 25rem;
}


.ant-card-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}

#SwitchToReadingMode-Small14 {
  display: none !important;
}

.doc-viewer-toolbar {
  display: none !important;
}

#ChromelessStatusBar.RightDock {
  display: none !important;
}

#SvgjsText1025 {
  width: 50% !important;
}

#SvgjsG1024 {
  width: 10rem !important;
}

.g {
  display: none !important;
}

.ant-picker-content>thead>tr>th {
  padding: 1rem !important;
  font-size: 21px !important;
  font-weight: 700 !important;


}

.ant-picker-content>thead>tr>th:nth-child(1) {
  background-color: #0c4f4d !important;
  font-size: 0 !important;

}

.ant-picker-content>thead>tr>th:nth-child(1)::after {
  background-color: #0c4f4d !important;
  content: "SUN" !important;
  font-size: initial !important;
}

.ant-picker-content>thead>tr>th:nth-child(2) {
  background-color: #e18e00 !important;
  font-size: 0 !important;

}

.ant-picker-content>thead>tr>th:nth-child(2)::after {
  background-color: #e18e00 !important;
  content: "MON" !important;
  font-size: initial !important;
}



.ant-picker-content>thead>tr>th:nth-child(3) {
  background-color: #85b21f !important;
  font-size: 0 !important;

}

.ant-picker-content>thead>tr>th:nth-child(3)::after {
  background-color: #85b21f !important;
  content: "TUE" !important;
  font-size: initial !important;
}

.ant-picker-content>thead>tr>th:nth-child(4) {
  background-color: #85096a !important;
  font-size: 0 !important;

}

.ant-picker-content>thead>tr>th:nth-child(4)::after {
  background-color: #85096a !important;
  content: "WED" !important;
  font-size: initial !important;
}

.ant-picker-content>thead>tr>th:nth-child(5) {
  background-color: #c50406 !important;
  font-size: 0 !important;
}

.ant-picker-content>thead>tr>th:nth-child(5)::after {
  background-color: #c50406 !important;
  content: "THU" !important;
  font-size: initial !important;
}

.ant-picker-content>thead>tr>th:nth-child(6) {
  background-color: #006098 !important;
  font-size: 0 !important;
}

.ant-picker-content>thead>tr>th:nth-child(6)::after {
  background-color: #006098 !important;
  content: 'FRI';
  font-size: initial !important;
}

.ant-picker-content>thead>tr>th:nth-child(7) {
  background-color: #0e763c !important;
  font-size: 0 !important;
}

.ant-picker-content>thead>tr>th:nth-child(7)::after {
  background-color: #0e763c !important;
  font-size: initial !important;
  content: 'SAT';
}

.carousel-root {
  width: 90% !important;
  margin: 0 6rem !important;
}

.slider-wrapper {
  background: rgba(18, 45, 83, 0.0784313725) !important;
  border-radius: 1rem !important;
  height: 28rem !important;
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1;  */
  background: transparent;
}

::-webkit-scrollbar-track:hover {
  background: #e2e2e2;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e2e2e2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dot {
  background: #000 !important;
}

.courseName_1 {
  font-size: 1.8rem;
}

@media screen and (max-width: 791px) {
  .ant-picker-content>thead>tr>th {
    padding:.5rem !important;
  }

}
@media screen and (min-width: 200px) and (max-width: 600px) {

  .ant-picker-content>thead>tr>th {
    width: 100% !important;
  padding: .3rem !important;
  }

  .ant-picker-content>thead>tr>th:nth-child(1)::after {
    content: "SUN" !important;
    font-size: 0.8rem !important;
  }

  .ant-picker-content>thead>tr>th:nth-child(2)::after {
    content: "MON" !important;
    font-size: 0.8rem !important;
  }

  .ant-picker-content>thead>tr>th:nth-child(3)::after {
    content: "TUE" !important;
    font-size: 0.8rem !important;
  }

  .ant-picker-content>thead>tr>th:nth-child(4)::after {
    content: "WED" !important;
    font-size: 0.8rem !important;
  }

  .ant-picker-content>thead>tr>th:nth-child(5)::after {
    content: "THU" !important;
    font-size: 0.8rem !important;
  }

  .ant-picker-content>thead>tr>th:nth-child(6)::after {
    content: "FRI" !important;
    font-size: 0.8rem !important;
  }

  .ant-picker-content>thead>tr>th:nth-child(7)::after {
    content: "SAT" !important;
    font-size: 0.8rem !important;
    width: 50px !important;
  }
}