.admin_panel {
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 1rem;
}

.admin_panel h1 {
    margin: 0 1rem;
}

.adminWSWrp {
    padding: 2rem;
    min-height: 100vh;

}

.admin_panel_companylist {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 1rem;
    grid-gap: 1rem
}

.admin_panel_companylist_card {
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-decoration: none;
    color: black;
    display: flex;
    min-width: 15rem;
    flex-direction: column;
    justify-content: space-between;

}

.ellips {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* Limits to 3 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
}

.admin_panel_com_logo {
    width: 100%;
    height: 10rem;
    margin-bottom: 1rem;
}

.admin_panel_com_logo img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}


.workShopList_header {
    display: flex;
    justify-content: space-between;
}

.modelFormWrp {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
}

.workshopimage {
    display: flex;
    flex-direction: column;
}

.workshopimage label {
    color: #505050;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    margin-bottom: 0.2rem;

}

.modelButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.modelButton Button {
    /* background-color: ; */
}

.wsCardBtn {
    align-self: flex-end;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.previewImage {
    width: 10rem;
    height: 10rem;

}

@media screen and (max-width: 1400px) {

    .admin_panel_companylist {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 991px) {
    .admin_panel_companylist {
        grid-template-columns: repeat(2, 1fr);
        overflow: auto;
    }
}

@media screen and (max-width: 600px) {
    .admin_panel_companylist {
        min-width: 15rem !important;
        grid-template-columns: 1fr;
    }

    .enrollWorkshopBtn {
        margin: .7rem;
    }

    .workShopList_header {
        flex-direction: column;
    }
}

@media screen and (max-width: 400px) {

    .admin_panel_com_logo {
        width: 100%;
        height: 7rem;
        margin-bottom: 1rem;
    }

    .admin_panel_companylist {
        padding: .5rem;
    }

    .admin_panel_companylist_card {
        padding: 1rem;
        border-radius: .5rem;
    }

    .admin_panel_com_logo img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}