.coursesWrp{
  background: var(--background-gradient);
}

.courses{
  font-family: 'Segoe UI Semibold';
  min-height: 100vh;
}
.cardWrapper {
  width: 100%;
  height: 100%;
  border-radius: .3rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform 0.3s ease;
}

.cardWrapper:hover {
  transform: scale(1.05) translateZ(0); /* Scale up and trigger hardware acceleration */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); /* Adjust box shadow on hover if needed */
}

.card-content {
  padding: 20px;
}
.courses h1{
  padding: 0rem;
}

.course_card:hover {
  border: 1px solid #f2b036;

}
.ControlBtn
{
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 1rem;

}

.grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem 4rem;
  padding-top: 1rem;
}
.cardWrapper{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 1rem;
  background: white;
  padding: 0.5rem;
  font-family: "GlacialIndifference-Regular" !important;
}
.cardContent{
  margin: 1rem;
  color: black;
  text-align: center;
}
.imageWrapper{
  width: 100%;
  height: 15rem;
}
.imageWrapper > img{
  width: 100%;
  height: 100%;
  object-fit: fill;
  mix-blend-mode: multiply;
}

.coursemodalDate{
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  
}

.description {
  min-height: fit-content;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}




@media screen and (max-width: 1400px) {
  .grid{
      grid-template-columns: repeat(3,1fr);
  }
}
@media screen and (max-width: 991px) {
  .grid{
      grid-template-columns: repeat(2,1fr);
  }
}
@media screen and (max-width: 591px) {
  .grid{
      grid-template-columns: 1fr;
  }
  
}

.heading {
  margin: 0;
  padding: 0;
  /* padding-bottom: 1rem; */
}
.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.lession{
  margin-right: 0.5rem;  
}
