.calenderPageWrp {
    background: var(--background-gradient);
}

.calenderpage {
    padding: 2rem;
}

.calenderpageWrp {
    padding: 2rem;
    border-radius: 0.5rem;
}

.calender {
    z-index: 1 !important;
    padding: 2rem;
    margin-top: 1rem;
    border-radius: 1rem;
    background-color: #fff;
    overflow-y: scroll;

}


.upComCourseList {
    padding: 0;
}


.content {
    list-style: none !important;
}


.modalDataWrp {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: .5rem;
}

.modalDataWrp p {
    font-size: medium;
    font-weight: 700;
}

.Dot {
    display: flex;
    align-items: center;
    width: 13px;
    height: 10px;
    margin-top: 0.5rem;
    margin-right: 0.2rem;

}

.redDot {
    background-color: red;
    border-radius: 50%;
    height: 10px !important;
    width: 10px !important;
    margin-right: 0.2rem;
}

.subOrdinate {
    background-color: #ff0 !important;
}

.success {
    background-color: green !important;
}

.error {
    background-color: red !important;
}

.warning {
    background-color: blue !important;
}

.Dot img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.modalCourseList :hover {
    color: var(--primary-backgroundColor);
    text-decoration: underline;
}

.modalBtn {
    position: absolute;
    right: 2rem;
    background-color: #171151;
    color: white;
}

.Mbtn {
    background-color: #171151;
    color: #fff;
}

.modalViewMoreBtn {
    display: flex;
    justify-content: flex-end;
}

.success1 {
    position: absolute;
    background: #7fdda58c !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
    overflow: auto;
    overflow: scroll;

}

.error1 {
    position: absolute;
    background: #f090968c !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
    overflow: auto;
    overflow: scroll;

}

.subOrdinateBg {
    position: absolute;
    background: #ffff007d !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
    overflow: scroll;

}

.adminBg {
    position: absolute;
    background: #c6fff4b3 !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
    overflow: scroll;

}

.warning1 {
    overflow: auto;
    position: absolute;
    background: #ffd3828c !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
    overflow: scroll;
}

.selDate {
    margin-top: 1rem;
}

.flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
}

.ant-picker-calendar {
    min-width: 45rem;
}


.contenWrp {
    font-size: 1rem;
}

.calpdfWrp {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        margin: 0;
    }
}

.pdfCanvas {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pdfCanvas {
    filter: contrast(1.2) saturate(1.2);
  }

.controllsAndInput {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    margin-top: 1rem;
  gap: 1rem;


}



.displayControlls {
    display: flex;
    justify-content: center ;
    gap: 1rem;
    align-items: center;
}


.contrBtn {
    width: 1.5rem;
    height: 1.5rem;
    background-color: #171151;
    color: #fff;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    width: 100%;
    margin-right: .5rem;
    border: 1px solid black;
}

.downloadPdf{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    background-color: #171151;
    color:#fff;
    padding: .3rem;
    border-radius: 50%;
    font-weight: 900;
    
}

.downloadPdf:hover{
    cursor: pointer;
}
.pdfInput {
    display: flex;
    flex-direction: column;
    justify-content: center;

    label {
        font-weight: 700;
    }

    input {
        border: 1px solid black;
        border-radius: .3rem;
        margin-top: .3rem;
        padding: .5rem .5rem;
    }
}

.fullScreenBtn {
    font-size: 1rem;
    background-color: #171151;
    color: #fff;
    border-radius: 1rem;
    border: none;
    padding: .5rem 1rem;
}

@media screen and (max-width: 600px) {
    .CalHeader {
        display: grid;
        margin: 0;
        margin-bottom: 1rem;
    }

    .calender {
        padding: 1rem;
        border-radius: .5rem;
    }

    .calpdfWrp {
        flex-direction: column;
    }

    .contenWrp {
        font-size: .7rem;
    }

    .calenderpage {
        padding: .5rem;
    }
}