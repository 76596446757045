.admin_panel {
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 1rem;
}

.ellips {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Limits to 3 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
}
.compwrp{
    min-height: 100vh !important;

}
.admin_panel h1 {
    margin: 0 1rem;
}

.admin_panel_companylist {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 1rem;
    grid-gap: 1rem
}

.admin_panel_companylist_card {
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-decoration: none;
    color: black;
    min-width: 15rem;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

}


.buttons{
    align-self: flex-end;
margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin_panel_com_logo{
    width: 100%;
    height: 150px;
}

.admin_panel_com_logo img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}



@media screen and (max-width: 1440px) {
    .admin_panel_companylist {
        padding: 1rem;
        grid-template-columns: repeat(3, 1fr);
        overflow: auto;
    }
}
@media screen and (max-width: 1200px) {
    .admin_panel_companylist {
        padding: 1rem;
        grid-template-columns: repeat(3, 1fr);
        overflow: auto;
    }
}
@media screen and (max-width: 991px) {
    .admin_panel_companylist {
        padding: 1rem;
        grid-template-columns: repeat(2, 1fr);
        overflow: auto;
    }
}

@media screen and (max-width: 600px) {
    .admin_panel_companylist {
        grid-template-columns: 1fr;
        overflow: hidden;
    }
    .AddCompBtn{
        min-width: 15rem;
        }
    .flex{
        flex-direction: column;
        gap: 2rem;
    }
    .admin_panel_companylist_card{
        min-width: 100%;
    }
}
@media screen and (max-width: 400px) {
    .buttons{
        bottom: 1rem;
    }
    .admin_panel_companylist_card{
        border-radius: .5rem;
    }
}
.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.uploadPhoto {
    width: 35%;
    height: 8rem;
    padding: 1rem;
    border-radius: .3rem;
    border: 2px solid white;
}

.profilePhoto {
    width: 5rem;
    height: 5rem;
}
.profilePhoto > img{
    width: 100%;
    height: 100%;
}