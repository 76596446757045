.header {
  border-bottom: 2px solid #f5f2f0;
  background-color: #171151;
  border: none;
}

.loginWrpdiv {
  display: grid;
  place-items: center;
  height: fit-content;
  padding: 6rem 0;
}


.loginWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}


.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 3rem 5rem;
  min-height: 23.5rem;
}

.logindata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0.65rem;
}

.image {
  width: 60%;
  height: 29.5rem;

}

.leftImage {
  border-radius: .3rem;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.input {
  margin-top: 0.5rem;
  color: #8a7d61;

}

.log_btn {
  width: fit-content;
  padding: 0.8rem 8.5rem;
  border-radius: 0.3rem;
  background-color: var(--primary-backgroundColor);
  color: #fff;
  font-weight: 600;
  border: none;
  width: 100%;
  margin-top: 1rem;
  cursor: pointer;
}

.form_forgot_pass {
  text-decoration: none;
}

.forgot {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.sign_btn {
  border: none;
  background-color: #fff;
}

.header_title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.5rem;
  color: black;
  text-decoration: none;
}

.header_title p {
  margin: 0;
  display: inline-block;
}

.footer {
  width: 100%;
  background: url(../../../assets/Footer-bg.png);
  color: white !important;
}


.menuBar_link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: .8rem 0;
  border-bottom: 1px dashed #ffffff1c !important;
  padding: .2rem auto;
  color: #fff;
  font-size: .9rem;
  text-decoration: none;
  text-align: center;
}



.open {
  display: none;
}






@media screen and (max-width: 991px) {
  .login {
    min-height: 25rem;
    padding: 0 4rem;
  }

  .image {
    height: 25rem;
  }

  .log_btn {
    padding: .8rem 5rem;
  }
}

@media screen and (max-width: 781px) {

  .loginWrpdiv {
    padding: 2rem 1rem;
  }

  .image {
    display: none;
  }

  .logindata {
    width: 100%;

    TTInput {
      width: 25rem;
    }
  }

  .log_btn {
    padding: .8rem 5rem;
  }
}

@media screen and (max-width: 600px) {
  .loginWrapper {
    display: flex;
    justify-content: center;
  }

  .login {
    width: 60%;
    /* padding: 3rem; */
  }

  .logindata {
    width: 100%;
  }

  .open {
    display: block;
    position: fixed;
    top: 4rem;
    left: 0;
    width: 0;
    height: 0;
    background-color: #171151;
    overflow: hidden;
    transition: width 0.5s ease, transform 0.5s ease;
    transform: translateX(-100%);
  }

  .show {
    top: 4rem;
    height: 100%;
    width: 200px;
    transform: translateX(0);
  }
}


@media screen and (max-width: 480px) {
  .loginWrapper {
    display: flex;
    justify-content: center;
  }

  .login {
    width: 60%;
  }

  s .logindata,
  .input {
    width: 100%;
  }

  .open {
    display: block;
    position: fixed;
    top: 4rem;
    left: 0;
    width: 0;
    /* Initially hide the sidebar */
    height: 0;
    background-color: #171151;
    overflow: hidden;
    transition: width 0.5s ease, transform 0.5s ease;
    /* Add transition properties */
    transform: translateX(-100%);
    /* Move sidebar off-screen initially */
  }

  .show {
    top: 4rem;
    height: 100%;
    width: 200px;
    /* Set width of the sidebar when it's open */
    transform: translateX(0);
  }
}