inputWrapper{
    width: 100%;
  }
  .wrapper{
    height: 40px;
    /* height: 40px;
    width: 100%;
    background-color: transparent;
    border: 1px solid #60606099;
    padding: 0rem 0.5rem;
    border-radius: 0.2rem;
    color: #000;
    font-family: 'Poppins', sans-serif;
    &:hover{
      outline: none;
    } */
  }
  .label{
    color: #505050;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    margin-bottom: 0.2rem;
  }
  .disabled{
    opacity: 0.8 !important;
    background-color: #f0f0f0 !important;
  }