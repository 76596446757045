.workshopwrp{
  margin: 0 6rem;
}

.courseHeader{
  display: flex;
  align-items: center;
  gap: 1rem;

}

.courseHeader h1{
  margin: 0;
}

.heading_logo{
  margin-left: -.7rem;
  margin-top: -.3rem;
  height: 4.6rem
}
.heading_logo img{
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.serviceHeaderimg{
  width: 4rem;
}
.sec5_info_img > img {
  width: 100%;
  object-fit: cover;
}

.worksopCardImg{
  width: 100%;
  height: 12rem;
}

.worksopCardImg img{
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.CourseList_info{
  display: flex;
  flex-direction: column;
  font-family: "Roboto Medium";
}

.firstLineCards{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin: 1rem 0.2rem;
}


.secondLineCards{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 13rem;
}
.workshopCard{
  width: 100%;
  cursor:default;
}



@media (max-width: 991px) {
  .courseHeader{
    display: grid;
    place-items: center;
  }
    .heading_logo{
      width: 20%;
      height: 3rem;
    }
    .heading_logo img{
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .CourseList_info{
      gap: 0;
      margin:0 ;
    }
    .firstLineCards,.secondLineCards{
      margin: 2rem;
      display: flex;
    }
    .worksopCardImg{
      height:6rem;
    }
    .worksopCardImg img{
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .secondLineCards{
      padding: 0 7rem;

    }
  }


@media (max-width: 600px) {

  .workshopwrp{
    margin: 0 3rem !important;
  }

  .worksopCardImg{
    width: 100%;
    height: 12rem !important;
  }
  
  .worksopCardImg img{
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
.courseHeader{
  display: grid;
  place-items: center;
}
  .heading_logo{
    width: 30%;
    height: 3rem;
  }
  .heading_logo img{
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .CourseList_info{
    gap: 2.5rem;
    margin:0;
    
  }
  .firstLineCards,.secondLineCards{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    margin: 0;
  }

  .firstLineCards{
    gap: 2rem !important;
  }
  .secondLineCards{
    padding: 0;
    margin-top: 0;
    
  }

  .workshopCard{
    width: 100%;
  }
  .workshopCard2{
    width: 100% !important;
  }
}

@media (max-width: 786px) {


  .workshopwrp{
    margin: 0 2rem;
  }
  .worksopCardImg{
    height:4rem;
  }
  .worksopCardImg img{
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .courseHeader{
    display: grid;
    place-items: center;
  }
    .heading_logo{
      width: 30%;
      height: 3rem;
    }
    .heading_logo img{
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .CourseList_info{
      gap: 2rem;
      margin:0;
      
    }
    .firstLineCards{
      margin:0;
      gap: .5rem;
    }
    .secondLineCards{
      padding: 0;
      gap: 2rem;
      margin-top: 0;
    }

    .workshopCard2{
      width: 12rem;
    }
  }

.heading{
  margin: 0;
  padding: 0;
}


.carouselContainer{
  /* padding: 2rem; */
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  /* background-color: #171151;
  padding: 2rem 0; */
}

.carouselImage{
  width: 40rem;
  height: 25rem;
}

.carouselImage img{
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.carouselButtons{
  width: 100%;
  display: flex;
  justify-content: space-between;

}

.carouselButtons Button{
  background-color: #171151;
  color: white;
}


.prevPageBtn{
  position: absolute;
  left: 10rem;
  display: grid;
  place-items: center;
  background-color:#171151 ;
  color: aliceblue;
  font-size: larger;
}
.nextPageBtn{
  position: absolute;
  right: 10rem;
  display: grid;
  place-items: center;
  background-color:#171151 ;
  color: aliceblue;
  font-size: larger;
}
.videoWrp{
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.pdfCanvas {
  max-width: 100% !important;
  max-height: 80% !important;
}