.HomeContentWrapper {
    background: var(--background-gradient);
    backdrop-filter: blur(10px);

    min-height: fit-content;
}

.HomeContent {
    overflow-y: auto;
    padding: 2rem;
    font-family: 'GlacialIndifference-Regular' !important;

}

.home_page_wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
}

.header {
    border-radius: 1rem;
    background-color: #fff;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.home_page_contant {
    text-align: center;
    border-radius: .3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.home_content_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.dash_inline_wrapper {
    position: relative;
    width: 30%;
    margin-top: 8rem;
    display: grid;
    place-items: center;
    background-color: #fff;
    line-height: 1.5rem;
    text-align: center;
    border-radius: 1rem;
    /* overflow: hidden; */
    /* font-family: 'GlacialIndifference-Regular' !important; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dash_inline_wrapper p {
    padding: .5rem;
    font-size: 1.03rem;
    font-weight: 600;
    line-height: 1rem;
}

.profileCardContent {
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    margin-top: 5.2rem;
    color: black;
}

.profileCardContent p {
    color: black !important;
}

.profileCardContent h2 {
    margin: 0;
    font-weight: 900;
    font-size: 1.5rem;
}

.inlineDetails {
    text-align: center;
    margin-left: 2rem !important;
}

.home_logo {
    position: relative;
    width: 75%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    overflow: hidden;
    border-radius: 1rem;
    line-height: 1.5rem;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.header_Emp_photo {
    width: 100%;
    height: 10rem;
}

.home_page_content_img {
    width: 100%;
    height: 25rem;
}

.home_page_content_img img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.header_sec3 {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    gap: 2rem;
    border-radius: 1rem;
    margin-top: 1rem;
}

.sec3_left {
    width: 40%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
}

.chartLabel {
    margin: 1.5rem;
}

.chartLabel label {
    font-weight: 600;
    font-size: 1.5rem;
}


.DisplaychartLabel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    padding: 1rem;
}

.labels {
    text-align: left;
    display: flex;
    align-items: center;
    /* gap: 1rem; */
    font-size: 14px !important;
}

.sec3_right {
    font-family: "Roboto Medium";
    width: 70%;
    height: 100%;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* font-family: 'GlacialIndifference-Regular' !important; */
    padding: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    text-align: justify;
    max-height: 580px !important;
    overflow: auto;
}

.content {
    font-size: 1.2rem;
    color: black !important;
    font-family: Roboto Medium;
    line-height: 30px;
}

.sec3_Learn2Lead {
    color: orange;
    font-weight: 500;
}

.sec3_iCretegy {
    font-weight: 700;
    color: orange;
}

.sec3_Why {
    font-weight: 600;
    color: green;
}

.header_logo_wrapper {
    border-radius: .3rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    /* padding: 1rem; */
}

.header_logo {
    width: 100%;
    height: 3rem;
    overflow: hidden;
}

.header_logo_img {
    width: 100%;
    height: 3rem;
    object-fit: contain;
}

.inline_header_img {
    height: 17rem;
    position: absolute;
    padding: 2rem;
    top: -10rem;
    border-radius: 1rem;
    overflow: hidden;
}

.inline_header_img img {
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.header_contant p {
    font-size: xx-large;
    font-weight: 800;
}

.header_contant i {
    font-size: x-large;
}

.header_sec3 p {
    font-size: larger;
}




.EmpList h1 {
    margin: 0;
    margin-top: 2rem;
}

.EmpList_cards {
    border-radius: 1rem;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;
}



.EmpList_cards h2 {
    margin: 0 1rem;
    /* font-size: x-large; */
}

.EmpList_card {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    padding: 1rem;
    border-radius: 1rem;
    /* min-height: 14rem; */
    /* width: 14rem; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: transform 500ms ease;
    min-height: 20rem;

    /* transition-delay: .2s; */
}


.EmpList_card:hover {
    transform: scale(1.1);
    transition-duration: 600ms;
}



.EmpList_card_img_wrp {
    width: 7rem;
    height: 7rem;
    margin-bottom: 1rem;
    font-size: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.EmpList_card_img_wrp img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 50%;
}

h2,
p {
    text-decoration: none;
    color: black;
    margin-top: 0;
}


.EmpList_card button {
    background-color: none;
    padding: .5rem 1rem;
    margin-top: 1rem;
    background-color: var(--primary-backgroundColor);
    color: white;
    border-radius: 1rem;
    border: none;
}

.Link {
    color: #fff;
}

.chartheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chartheader label {
    font-size: 1rem;
}

.ceoCards {
    margin-top: 2rem;
}

.ceoCards h1 {
    margin: 0;
}

.ceoCardswrp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

.ceoHomeCard {
    background-color: #fff;
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    border-radius: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 2rem;
    transition: transform 500ms ease;
}

.ceoHomeCard:hover {
    transform: scale(1.05);
    transition-duration: 600ms;
}

.adminhome_card_icons {
    display: grid;
    place-content: center;
}

.adminhome_card_icons img {
    width: 8rem;
    height: 8rem;
}


.empdesignation {
    line-height: .8rem;
    margin-top: .4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.submitBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;

    Button {
        background-color: #171151;
        color: #fff;
    }
}


@media screen and (max-width: 1400px) {
    .coursesWrp {
        grid-template-columns: repeat(3, 1fr);
    }




}



@media screen and (max-width: 991px) {
    .home_page_contant {
        display: flex;
        gap: 1rem;
    }

    .home_page_content_img {
        height: 16rem;
    }

    .inline_header_img {
        height: 12rem;
        top: -6rem;
    }

    .dash_inline_wrapper {
        margin-top: 4rem;
    }

    .home_logo {
        margin-left: 0;
    }

    .sec3_right {
        height: 31.5rem;
    }

    .content {
        font-size: 1rem;
    }

    .chartheader {
        display: grid;
        gap: 1rem;
    }

    .profileCardContent {
        h2 {
            font-size: 1.1rem;
        }

        p {
            font-size: .8rem;
        }
    }
}

@media screen and (max-width: 791px) {
    .home_page_contant {
        display: flex;
        gap: 1rem;
    }

    .home_page_content {
        height: 16rem;
    }
    .dash_inline_wrapper{
        margin-top: 4.5rem;
    }

    .home_logo {
        height: 16rem;
    }
    .inline_header_img{
        height: 11rem;
        padding: 1.5rem;
    }

    .profileCardContent {
        margin-top: 2.5rem;

        p {
            padding: 0;
        }
    }

    .flex {
        gap: 1rem;

        h2 {

            font-size: medium;
        }

    }

    .content {
        font-size: .8rem;
        line-height: 20px;
    }

    .header_sec3 {
        height: 26rem;

        p {

            font-size: small;
        }
    }

    .sec3_right,
    .sec3_left {
        height: 100%;
        width: 50%;
    }

   
}


@media screen and (max-width: 600px) {
    .home_page_contant {
        display: grid;
    }

    .adminhome_card_icons img {
        width: 6rem;
        height: 6rem;
    }

    .HomeContentWrapper {
        min-height: 107rem;
    }

    .dash_inline_wrapper {
        width: 100%;
    }

    .inline_header_img {
        width: 11rem;
        height: 12rem;
        top: -7rem;

        img {
            object-fit: fill;
        }
    }

    .HomeContent {
        overflow: hidden;
        min-height: fit-content;
    }

    .home_logo {
        width: 100%;
    }

    .profileCardContent {
        height: 6rem;
        margin-top: 4rem;
    }

    .header_sec3 {
        display: grid;
    }

    .sec3_left,
    .sec3_right {
        width: 100%;
    }

    .sec3_right {
        height: fit-content;
    }

    .ant-layout .ant-layout-has-sider {
        height: 100rem;
    }
    .ceoCardswrp {
        grid-template-columns: 1fr;
    }


}



.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.flex>h2 {
    margin: 0px !important;
}

.editorWrapper {
    margin-top: 1rem;
}

.inp {
    margin-bottom: 0.5rem;
}