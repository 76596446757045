.header {
  border-bottom: 2px solid #f5f2f0;
  background-color: #171151;
  border: none;
}

.wrapper {
  width: 100%;
}

.signupDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}






.signupWrapper {
  display: flex;
  width: 100%;
}


.signup {
  margin: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 3rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}



.basic_info {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 3rem;
}




.form {
  margin-top: 1.5rem;
  padding: 1rem;
  width: 100%;
}


.signup_btn {
  width: fit-content;
  border-radius: 0.3rem;
  padding: 1rem;
  background-color: var(--primary-backgroundColor);
  color: white;
  font-weight: 800;
  font-size: medium;
  border: none;
  width: 100%;
  margin-top: 1rem;
}

.signup_btn:hover {
  cursor: pointer;
}

.login_btn {
  margin-top: 1rem;
  font-size: medium;
  border: none;
  background-color: #fff;
}

.submit {
  margin-top: 1.5rem;
}

.submit>button {
  margin-top: 2rem;
}

.open {
  display: none;
}


.menuBar_link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: .8rem 0;
  border-bottom: 1px dashed #ffffff1c !important;
  padding: .2rem auto;
  color: #fff;
  font-size: .9rem;
  text-decoration: none;
  text-align: center;
}




.footer {
  width: 100%;
  margin-top: 5rem;
  background: url(../../../assets/Footer-bg.png);
  color: white !important;
}



@media screen and (max-width: 991px) {
  .signup {
    padding: 2rem;
    /* margin: 3rem 2rem ; */
  }

  .basic_info,
  .identity,
  .address {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }

  .pin_email_contact,
  .company {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    grid-gap: 1rem;
  }

  .identity>.logindata>.input {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .signup {
    padding: 2rem;
    width: 70%;
    margin: 2rem;
  }
  .footer{
    margin-top: 0;
  }


  .basic_info,
  .identity,
  .pin_email_contact,
  .company {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    grid-gap: 0;
  }

  .identity>.logindata>.input {
    width: 100%;
  }

  .open {
    display: block;
    position: fixed;
    top: 4rem;
    left: 0;
    width: 0;
    height: 0;
    background-color: #171151;
    overflow: hidden;
    transition: width 0.5s ease, transform 0.5s ease;
    transform: translateX(-100%);
  }
  
  .TTinput{
    margin-bottom: 1rem;
  }

  .submit{
    margin-top: 0;
  }

  .show {
    top: 4rem;
    height: 100%;
    width: 200px;
    transform: translateX(0);
  }


}

@media screen and (max-width: 480px) {


  .signup_btn{
    padding: 1rem;
  }

  .basic_info,
  .identity,
  .pin_email_contact,
  .company {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    grid-gap: 0;
  }

  .identity>.logindata>.input {
    width: 100%;
  }

  .open {
    display: block;
    position: fixed;
    top: 4rem;
    left: 0;
    width: 0;
    /* Initially hide the sidebar */
    height: 0;
    background-color: #171151;
    overflow: hidden;
    transition: width 0.5s ease, transform 0.5s ease;
    /* Add transition properties */
    transform: translateX(-100%);
    /* Move sidebar off-screen initially */
  }

  .show {
    top: 4rem;
    height: 100%;
    width: 200px;
    /* Set width of the sidebar when it's open */
    transform: translateX(0);
    /* Move sidebar into view */
  }


}