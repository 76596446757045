.calenderPageWrp{
    background: var(--background-gradient);
}

.calenderpage{
    padding: 2rem;
}
.calenderpageWrp{
    padding: 2rem;
    border-radius: 0.5rem;
}
.calenderWrp > h1{
    margin: 0;
}

.calender{
    padding: 2rem;
    margin-top: 1rem;
    border-radius: 1rem;
    background-color: #fff;
}
.content{
    list-style: none !important;
}


.modalDataWrp{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: .5rem;
}

.modalDataWrp p{
    font-size: medium;
    font-weight: 700;
}
.Dot{
    display: flex;
    align-items: center;
    width: 13px;
    height: 10px;
    margin-top: 0.5rem;
    margin-right: 0.2rem;

}
.redDot{
    background-color: red;
    border-radius: 50%;
    height: 10px !important;
    width: 10px !important;
    margin-right: 0.2rem;

}
.success{
    background-color: green !important;
}

.error{
    background-color: red !important;
}

.warning{
    background-color: blue !important;
}

.Dot img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.modalCourseList :hover{
    color: var(--primary-backgroundColor);
    text-decoration: underline;
}

.modalBtn{
    position: absolute;
    right: 2rem;
    background-color: #171151;
    color: white;
}

.Mbtn{
    background-color: #171151;
    color: #fff;
}

.modalViewMoreBtn {
    display: flex;
    justify-content: flex-end;
}
.selectedBg{
  
}
.success1{
    position: absolute;
    background:  #7fdda58c !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;

}
.error1{
    position: absolute;
    background: #f090968c !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;

}
.warning1{
    position: absolute;
    background: #ffd3828c !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0.2rem !important;
}