.courseDetailPageWrp {
  background: var(--background-gradient);
}

.courseDetail {
  padding: 2rem;
  font-family: 'Aptos';
  overflow: scroll;
}


.presentationWrp {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-top: 1rem;
  overflow: auto;

}

.employeeinputs {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.inputWrp {
  overflow: auto;
  width: 90rem;
}


.presentation_ppt_wrapper {
  border-radius: 1rem;
  margin-top: 3rem;
}



.modalDataPdf {
  margin-top: 2rem !important;
}

.displayControlls {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}



.presentation_ppt_wrapper>h1,
.course_graph>h1,
.taskListWrapper>h1 {
  margin: 0;
}

.controllsAndInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;

}

.pdfInput {
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    font-weight: 700;
  }

  input {
    border: 1px solid black;
    border-radius: .3rem;
    margin-top: .3rem;
    padding: .5rem .5rem;
    border: none;
  }
}

.pptWrp {
  border-radius: 1rem;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  background: white;
  overflow: hidden;
  padding: 1rem;
  min-height: 400px;
}

.pptWrp DocViewer {
  min-height: 30rem;
  overflow: hidden;
}

.chart {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  overflow-y: scroll;

}

.chartwrp {
  min-width: 30rem;
}

.label {
  font-size: large;
  font-weight: 600;
}

.questionLabel {
  font-size: 1.1rem;
  font-weight: 600;
}

.input_wrapper {
  display: flex;
}

.input {
  width: 100%;
  margin-right: .5rem;
  border: 1px solid black;

}







.taskListWrapper {
  margin-top: 2rem;
}

.tasks {
  background-color: #fff;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
}

.table_inputs {
  display: flex;
  flex-direction: column;
  justify-content: 'space-around';
  margin: .5rem;
  gap: .5rem;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  gap: 2rem;
  /* width: 100%; */
}

.input_wrapper {
  width: 100%;
}



.selectWrp {
  margin-right: 1rem;
}

.addAnsBtn {
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  font-size: x-large;
  border-radius: 50%;
  margin-top: 1rem;
}

.checkbtnWrp {
  display: flex;
  /* gap:1rem; */
  max-width: 18rem;
border: none;
  justify-content: center;
border-radius: 1rem;
  margin-bottom: 1rem;
  background-color: #171151;
  }


.pdfCanvas {
  margin-top: 1rem;
  width: 100%;
}

.contrBtn {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #171151;
  color: #fff;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider{
  color: #000 !important;
  height: 2rem !important;
}

.checkBtn {
  width: 5rem;
  height: 2rem;
  background: none;
cursor: pointer;
  /* background-color: #171151; */
  color: #fff;
  border-radius: 1rem;
  font-size: small;
  font-weight: 600;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.ans_inline {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.ans_subordinate {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.flexSubordinate {
  display: flex;
  justify-content: space-around;
}

.answerByInSubWrp {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputAdmin {
  display: flex;
  flex-direction: column;
}

.assessmentByAdmin {
  display: grid;
  gap: 1rem;
}

.assessmentByAdmin select {
  border-radius: .3rem;
  padding: .5rem;
  width: 8rem;
}

.ans_Admin {
  display: flex;
  width: 100%;
  gap: 4rem;
}


.ans_Admin textarea {
  width: 100%;
  border-radius: .3rem;
  padding: .5rem;
  /* padding: 2rem; */
}

.empAssesment {
  width: 8rem;
}

.assessmentBySub {
  margin-left: 2.2rem;
  margin-right: -1.9rem;
}

.assessmentByInl {
  margin: 0 1rem;
  margin-left: 2rem;
  /* margin-right: -1.3rem; */
}

#ChromelessStatusBar {
  display: none !important;
}

#ChromelessStatusBar.RightDock {
  display: none;
}



.graphHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}

.graphHeader h1 {
  margin: 0;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownToggle {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px 12px;
  cursor: pointer;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.CollapseHeader {
  display: flex;
  justify-content: space-between;
  color: #171151;
  gap: 5rem;
}

.dropdownMenu ul {
  margin: 0;
  padding: 0;
}

.dropdown-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}


.deadlineDate {
  color: red !important;
  font-size: 1.1rem;
}

.sliderCounter {
  width: 15rem;
}


@media screen and (max-width: 991px) {
  .pptWrp {
    min-width: 300px;
    min-height: 300px;

  }
}

@media screen and (max-width: 791px) {
  .employeeinputs {
    display: grid;
  }

  .inputWrp {
    width: 100%;
  }

  .sliderCounter {
    width: 100%;
    margin-bottom: 3rem;
  }

  .pptWrp {
    min-height: 410px;
  }

  .presentationWrp {
    grid-template-columns: 1fr;
  }

  .checkbtnWrp {
    margin-bottom: 3rem;
  }

}


@media screen and (max-width:600px) {
  .pptWrp {
    min-width: 16rem;
    min-height: 10rem;
  }

  .CollapseHeader {
    gap: 0;
  }

  .courseName_1 {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }

  .deadlineDate {
    font-size: 1rem;
    text-align: right;
    height: 1rem;
  }


}